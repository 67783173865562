.searchBar_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.searchBar_box input {
  height: 35px;
  width: 80%;
  padding-right: 20px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
}

.searchBar_box input:focus{
  border: 2px solid #ffaa00;
}

.searchBar_box input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.searchBar_box span {
  width: 20%;
  height: 35px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffaa00;
  color: #fff;
  border-radius: 5px 0 0 5px;
  font-size: 20px;
}
